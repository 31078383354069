<!-- Template -->
<template>
  <div id="l-institutional" class="l-institutional">
    <header class="l-institutional__header">
      <OrganismTopMenu />
    </header>

    <main class="l-institutional__main">
      <slot></slot>
    </main>
    
    <footer class="l-institutional__footer">
      <OrganismListFooter />
    </footer>

    <OrganismSupportMenu />
    <OrganismCookieManager/>
  </div>
</template>

<!-- Script -->
<script setup>
// Dependências
import { pageData } from './data.js'

// Keys
import { menuKey } from '~/keys/layouts/institutional'

// Componentes
import OrganismTopMenu from '~/components/institutional/organisms/OrganismTopMenu/OrganismTopMenu.vue'
import OrganismListFooter from '~/components/institutional/organisms/OrganismListFooter/OrganismListFooter.vue'
import OrganismSupportMenu from '~/components/institutional/organisms/OrganismSupportMenu/OrganismSupportMenu.vue'
import OrganismCookieManager from '~/components/institutional/organisms/OrganismCookieManager/OrganismCookieManager.vue'

// Configurações
const route = useRoute()

// Meta dados
useHead({
  title: `${route.meta.title} | Alfa Empréstimo Consignado`,
  meta: [
    { name: 'description', content: route.meta.description },
    { name: 'keywords', content: route.meta.keywords }
  ]
})

// Lógica
const menu = ref({ ...pageData.menu })

function setMenu() {
  menu.value.isActive = !menu.value.isActive
}

provide(menuKey, { menu, setMenu })
</script>

<!-- Style -->
<style lang="scss" scoped>
@import '~/assets/scss/transational/bootstrap.scss';

.l-institutional {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  place-content: start stretch;
  width: 100svw;
  height: 100svh;

  &__header {
    position: relative;
    display: grid;
    place-content: stretch;
    @include box-shadow();
  }
  
  &__main {
    display: grid;
    place-content: stretch;
    /* overflow: hidden auto;
    @include scrollbar();
    @include scrollbox-shadow($alfa-neutral-tertiary-3); */
  }

  &__footer {
    display: grid;
    place-content: stretch;
  }
}
</style>