const pageData = {
  columns: [
    {
      class: 'about',
      logo: true,
      title: null,
      info: null,
      list: [
        {
          link: null,
          value: null,
          text: 'FINANCEIRA ALFA S. A. CRÉDITO',
          isExternal: null
        },
        {
          link: null,
          value: null,
          text: 'FINANCIAMENTO E INVESTIMENTOS',
          isExternal: null
        },
        {
          link: null,
          value: null,
          text: 'CNPJ: 17.167.412/0001-13',
          isExternal: null
        }
      ],
      paragraphs: [
        'Nossas redes sociais:'
      ],  
      social: [
        {
          link: 'https://www.facebook.com/financeiraalfa',
          value: null,
          text: 'Facebook',
          isExternal: true
        },
        {
          link: 'https://www.instagram.com/financeiraalfaoficial',
          value: null,
          text: 'Instagram',
          isExternal: true
        },
        {
          link: 'https://www.linkedin.com/showcase/financeira-alfa',
          value: null,
          text: 'Linkedin',
          isExternal: true
        },
        {
          link: 'https://www.youtube.com/@AlfaConglomerado',
          value: null,
          text: 'Youtube',
          isExternal: true
        }
      ]
    },
    {
      class: 'location',
      logo: false,
      title: {
        text: 'Localização',
        icon: 'house'
      },
      info: null,
      list: [
        {
          link: null,
          value: null,
          text: 'Avenida Paulista, 2.150, 17º andar',
          isExternal: null
        },
        {
          link: null,
          value: null,
          text: 'Bela Vista, São Paulo – SP',
          isExternal: null
        },
        {
          link: null,
          value: null,
          text: 'CEP 01310-300',
          isExternal: null
        }
      ],
      paragraphs: null,
      social: null
    },
    {
      class: 'contact',
      logo: false,
      title: {
        text: 'Informações de Contato',
        icon: 'phone'
      },
      info: {
        name: 'SAC Financeira Alfa',
        content: 'dias úteis, das 8h às 18h',
      },
      list: [
        {
          link: 'tel:40040044',
          value: '4004 0044',
          text: '(capitais e regiões metropolitanas)',
          isExternal: false
        },
        {
          link: 'tel:08007250044',
          value: '0800 725 0044',
          text: '(demais localidades)',
          isExternal: false
        },
        {
          link: 'https://wa.me/551133868659',
          value: '(11) 3386 8659',
          text: '(WhatsApp)',
          isExternal: true
        }
      ],
      paragraphs: null,
      social: null
    },
    {
      class: 'links',
      logo: false,
      title: {
        text: 'Links Rápidos',
        icon: null
      },
      info: null,
      list: [
        {
          link: '/',
          value: null,
          text: 'Página Inicial',
          isExternal: false
        },
        {
          link: '/vantagens-e-diferenciais',
          value: null,
          text: 'Vantagens e Diferenciais',
          isExternal: false
        },
        {
          link: '/quem-somos',
          value: null,
          text: 'Quem Somos',
          isExternal: false
        },
        {
          link: '/blog',
          value: null,
          text: 'Blog',
          isExternal: false
        },
        {
          link: '/ajuda',
          value: null,
          text: 'Ajuda',
          isExternal: false
        },
        {
          link: '/institutional/termos-de-uso',
          value: null,
          text: 'Termos de Uso',
          isExternal: false
        },
        {
          link: '/institutional/politica-de-privacidade',
          value: null,
          text: 'Política de Privacidade',
          isExternal: false
        },
        {
          link: '/institutional/lgpd',
          value: null,
          text: 'LGPD',
          isExternal: false
        },
        {
          link: '/institutional/alerta-antifraude',
          value: null,
          text: 'Alerta Antifraude',
          isExternal: false
        }
      ],
      paragraphs: null,
      social: null
    }
  ],
  copyright: {
    class: 'copyright',
    logo: false,
    title: null,
    info: null,
    list: null,
    paragraphs: [
      `Copyright ${ new Date().getFullYear() } - Banco Alfa Todos os direitos reservados`
    ],
    social: null
  },
  legalNotice: {
    class: 'legal',
    logo: false,
    title: {
      text: 'IMPORTANTE',
      icon: null
    },
    info: null,
    list: null,
    paragraphs: [
      `Este site - www.alfaconsignado.com.br - pertence à Financeira Alfa S.A. - CFI e/ou suas Afiliadas.`,
      `
        As operações de empréstimo consignado iniciadas e/ou realizadas por meio deste site não se utilizam 
        de Correspondentes no País.
      `,
      `
        A contratação de referida operação de empréstimo está sujeita a análise cadastral, margem consignável 
        disponível, aprovação de crédito e existência de convênio entre a Financeira Alfa e o respectivo 
        órgão/empresa empregadora. As parcelas da operação de empréstimo consignado serão debitadas da 
        folha de pagamento/benefício do tomador do crédito. As taxas de juros praticadas seguem regras 
        definidas e estão de acordo com a política de crédito da Financeira Alfa. A Financeira Alfa, de 
        acordo com a legislação vigente, disponibilizará previamente à eventual contratação, todas as 
        informações necessárias, de forma transparente, incluindo a taxa de juros praticada, eventuais 
        tarifas aplicáveis, encargos e demais impostos incidentes, bem como o custo efetivo total (CET) 
        da operação. O atraso no pagamento de qualquer valor devido, sujeitará o cliente a, dentre outras 
        medidas, ter incluído seu nome no cadastro de inadimplentes dos órgãos de proteção ao crédito, 
        o protesto do respectivo título e/ou, ainda, o ajuizamento de ações cabíveis de cobrança.
      `,
      `
        Mantenha seus dados cadastrais atualizados e, em havendo alguma alteração, comunicar à Financeira 
        Alfa.
      `
    ],
    social: null
  }
}

export { pageData }