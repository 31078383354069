<!-- Template -->
<template>
  <div class="o-top-menu">
    <nav class="o-top-menu__container">
      <div class="o-top-menu__container__icon">
        <NuxtLink to="/"><IconConsignado :width="200" :height="90" /></NuxtLink>
      </div>
    
      <ul class="o-top-menu__container__list">
        <li class="o-top-menu__container__list__item" v-for="(item, index) in menuList">
          <AtomListButton 
            :key="index"
            :link="item.link"
            :icon="item.icon"
            :label="item.label"
            :type="1"
          />
        </li>
      </ul>
    
      <button class="o-top-menu__container__button" type="button" @click="handleMenu">
        <i class="o-top-menu__container__button__icon" for="menu">
          <IconMenuOpen :width="50" :height="50" />
        </i>
  
        <span class="o-top-menu__container__button__title">MENU</span>
      </button>
    
      <div class="o-top-menu__container__actions">
        <NuxtLink class="o-top-menu__container__actions__link" to="/#">
          <span class="o-top-menu__container__actions__link__text">
            Empréstimo pessoal
          </span>
  
          <i class="o-top-menu__container__actions__link__icon">
            <IconFollow :width="24" :height="24" />
          </i>
        </NuxtLink>
  
        <button class="o-top-menu__container__actions__button" type="button" @click="handleMenu">
          Login
        </button>
      </div>
    </nav>
  </div>
</template>

<!-- Script -->
<script setup>
// Dependencias
import { inject } from 'vue'

// Keys
import { menuKey } from '~/keys/layouts/institutional'

// Icones
import IconConsignado from '~/assets/icons/IconConsignado.vue'
import IconMenuOpen from '~/assets/icons/IconMenuOpen.vue'
import IconFollow from '~/assets/icons/IconFollow.vue'

// Componentes
import AtomListButton from '~/components/institutional/atoms/AtomListButton/AtomListButton.vue';

// Logica
const { menu, setMenu } = inject(menuKey)
const menuList = [...menu.value.list.desktop]

function handleMenu() {
  setMenu()
}
</script>

<!-- Style -->
<style lang="scss" scoped>
@import '~/assets/scss/transational/bootstrap.scss';

.o-top-menu {
  display: grid;
  place-content: start stretch;
  place-items: center;
  background-color: $alfa-brand-primary-2;
  padding: 24px 32px;

  @media screen and (min-width: $min-desktop) {
    padding: 24px 80px;
  }
  
  &__container {
    display: grid;
    grid-template-columns: auto 1fr;
    place-content: stretch;
    width: clamp($min-mobile, 100%, $institutional-max-content);
    
    @media screen and (min-width: $min-desktop) {
      grid-template-columns: auto 1fr auto;
      place-items: center start;
      column-gap: 40px;
    }
    
    &__icon {
      display: grid;
      place-content: center;
    }
  
    &__list {
      display: none;
  
      @media screen and (min-width: $min-desktop) {
        place-self: center;
        display: grid;
        grid-auto-flow: column;
        place-content: center;
        column-gap: 24px;
      }
  
      &__item {
        display: grid;
        place-content: stretch;
      }
    }
  
    &__button {
      display: grid;
      place-self: center end;
      place-items: center;
      width: 55px;
      background-color: transparent;
  
      @media screen and (min-width: $min-desktop) {
        display: none;
      }
  
      &__icon {
        cursor: pointer;
        display: grid;
        place-items: center;
        place-content: center;
        width: 55px;
        height: 55px;
      }
  
      &__title {
        @include font-custom(14, 16, center, 'Montserrat Medium', $alfa-neutral-primary-1);
      }
    }
  
    &__actions {
      display: none;
  
      @media screen and (min-width: $min-desktop) {
        display: grid;
        grid-auto-flow: column;
        place-content: center;
        column-gap: 16px;
      }
  
      &__link {
        display: grid;
        grid-auto-flow: column;
        column-gap: 8px;
        place-content: center;
        place-items: center end;
  
        &__icon {
          display: block;
          width: 24px;
          height: 24px;
        }
  
        &__text {
          text-wrap: nowrap;
          @include font-custom(8, 28, center, "Montserrat Medium", $alfa-neutral-primary-1);
        }
      }
  
      &__button {
        @include button-custom(162, 26, 3, "Montserrat ExtraBold");
      }
    }
  }
}
</style>