<!-- Template -->
<template>
  <div
    class="o-support-menu" 
    :class="[
      { 'o-support-menu--in': menu.isActive === true },
      { 'o-support-menu--out': menu.isActive === false }
    ]"
  >
    <div class="o-support-menu__wrapper">
      <div class="o-support-menu__wrapper__header">
        <div class="o-support-menu__wrapper__header__icon">
          <NuxtLink to="/"><IconConsignado :width="210" :height="80" /></NuxtLink>
        </div>
    
        <button class="o-support-menu__wrapper__header__button" type="button" @click="handleMenu">
          <i class="o-support-menu__wrapper__header__button__icon">
            <IconMenuClose :width="18" :height="18" fill="#0391a2" />
          </i>
    
          <span class="o-support-menu__wrapper__header__button__title">Fechar</span>
        </button>
      </div>

      <nav class="o-support-menu__wrapper__navigation">
        <ul class="o-support-menu__wrapper__navigation__list">
          <li 
            v-for="(item, index) in menuList"
            class="o-support-menu__wrapper__navigation__list__item"
          >
            <AtomListButton
              :key="index"
              :link="item.link"
              :icon="item.icon"
              :label="item.label"
              :is-external="item.isExternal"
              :type="2"
            />
          </li>
        </ul>
      </nav>

      <div class="o-support-menu__wrapper__action">
        <button class="o-support-menu__wrapper__action__button" type="button">
          Login
        </button>
      </div>
    </div>
  </div>
</template>

<!-- Script -->
<script setup>
// Dependencias
import { inject } from 'vue'

// Keys
import { menuKey } from '~/keys/layouts/institutional'

// Icones
import IconConsignado from '~/assets/icons/IconConsignado.vue'
import IconMenuClose from '~/assets/icons/IconMenuClose.vue'

// Componentes
import AtomListButton from '~/components/institutional/atoms/AtomListButton/AtomListButton.vue';

// Variáveis
const { menu, setMenu } = inject(menuKey)
const menuList = [...menu.value.list.mobile]

function handleMenu() {
  setMenu()
}
</script>

<!-- Style -->
<style lang="scss" scoped>
@import '~/assets/scss/transational/bootstrap.scss';

.o-support-menu {
  z-index: 997;
  position: fixed;
  top: 0;
  left: 0;
  place-content: stretch;
  width: 100%;
  height: 100%;

  &:not(:is(&--in, &--out)) {
    display: none;
  }

  &:is(&--in, &--out) {
    display: grid;
  }

  &--in {
    @include animate-modal-toggle(1);
  }

  &--out {
    @include animate-modal-toggle(2, 500);
  }

  &::before {
    z-index: 998;
    position: absolute;
    content: '';
    display: grid;
    width: 100%;
    height: 100%;
    background-color: rgba($alfa-brand-primary-1, 0.25);
  }

  &--in &__wrapper {
    transform: translateY(100%);
    @include animate-vertical-translate(3, 350);
  }

  &--out &__wrapper {
    @include animate-vertical-translate(4);
  }
  
  &__wrapper {
    z-index: 999;
    display: grid;
    grid-template-rows: auto 1fr auto;
    place-content: start stretch;
    background-color: $alfa-neutral-primary-1;
    overflow: hidden;

    &__header {
      display: grid;
      grid-template-columns: auto 1fr;
      background-color: $alfa-brand-secondary-1;
      padding: 24px 32px;

      @media (hover: hover) {
        padding: 24px 48px 24px 32px;
      }
  
      &__icon {
        display: grid;
        place-content: center;
      }
  
      &__button {
        display: grid;
        place-self: center end;
        place-items: center;
        row-gap: 8px;
        width: 50px;
        background-color: transparent;
        border: 0;
  
        &__icon {
          @include button-round(43, $alfa-neutral-primary-1);
        }
  
        &__title {
          @include font-custom(12, 14.25, center, "Montserrat", $alfa-neutral-primary-1);
        }
      }
    }
  
    &__navigation {
      display: grid;
      place-content: stretch;
  
      &__list {
        display: grid;
        place-content: start stretch;
    
        &__item {
          display: grid;
          place-content: stretch;

          &:not(:last-child) {
            border-bottom: 1px solid $alfa-neutral-tertiary-2;
          }
        }
      }
    }

    &__action {
      display: grid;
      place-items: center;
      padding: 0 40px 40px;
      
      &__button {
        @include button-custom(334, 22.78, 3, "Montserrat ExtraBold");
      }
    }
  }
}
</style>